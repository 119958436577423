.location-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
    /* Center-align the columns */
    background-color: white;
    margin-top: 20px;
    padding: 30px;
}

.location-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.location-picture-text {
    border-radius: 20px;
    padding: 50px 10px;
    text-align: center;
}

.location-picture-text img {
    max-width: 100%;
    height: auto;
    width: 500px;
    height: 500px;
    object-fit: cover;
}

.location-iframe-container {
    position: relative;
    overflow: hidden;
}

.location-picture-text a {
    font-weight: bold;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the popup appears above other content */
}

.popup-content {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

}

.popup-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    /* Adjust the margin as needed */
}

.open-popup-button {
    background-color: #408289;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
}
.open-popup-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .location-section {
        flex-direction: column;
        align-items: center;
    }

    .location-picture-text a {
        font-size:large
    }

    .location-picture-text p {
        font-size: large;
    }
}