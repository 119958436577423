.home-page {
    text-align: center;
    overflow-x: hidden;
  }
  header {
    background-color: #333;
    color: white;
    padding: 20px;
  }
  header h1 {
    font-size: 5rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  @font-face {
    font-family: 'Malibu Ring';
    src: url('../images/weddings/KarenJerryWedding/malibu-ring.ttf') format('truetype'),
  }

  @font-face {
    font-family: 'Cormorant Garamond';
    src: url('../images/weddings/KarenJerryWedding/Cormorant-VariableFont_wght.ttf') format('truetype'),
  }
  @font-face {
    font-family: 'Cormorant SC Regular';
    src: url('../images/weddings/KarenJerryWedding/CormorantSC-Regular.ttf') format('truetype'),
  }



