.RSVP-page {
  text-align: center;
  overflow-x: hidden;
  background-color: white;
}

header {
  background-color: #333;
  color: white;
  padding: 20px;
}
header h1 {
  font-size: 2rem;
}
p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.google-form-section {
  max-width: 100%; /* Ensure the form doesn't exceed the screen width */
}

.google-form-section iframe {
  border: 0px;
  width: 694px;
  height: 697px;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Style adjustments for mobile screens */
@media screen and (max-width: 768px) {
  .google-form-section {
    padding: 20px; /* Add padding for better spacing on small screens */
  }

  /* Adjust the iframe's width and margin for mobile screens */
  .google-form-section iframe {
    width: 100%; /* Make the form full width on small screens */
    margin: 0; /* Remove any default margins */
  }
}
