/* src/components/PhotoCarousel.css */
.photo-carousel-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .photo-carousel-container {
    position: relative;
    width: 100%;
    max-height: 800px; /* Adjust the maximum height as needed */
    overflow: hidden;
  }
  
  .photo-carousel-image img {
    max-width: 100%;
    max-height: 100%; /* Reduce the image height */
    object-fit: fill;
    display: block;
    margin: 0;
  }
  
  .photo-carousel-arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .photo-carousel-arrows button {
    background: rgba(255, 255, 255, 0.2); /* Set an initial transparency */
    border: none;
    border-radius: 50%;
    padding: 10px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 2px solid transparent;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .photo-carousel-arrows button:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  
  .photo-carousel-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .dot.active {
    background: #000000;
  }

@media (max-width: 768px) {
  .photo-carousel-section {
    max-width: 90%; /* Reduce the width for smaller screens */
  }


  .photo-carousel-arrows button {
    width: 40px; /* Reduce button size for mobile */
    height: 40px;
    font-size: 20px; /* Reduce font size for mobile */
  }

}
  
  