.dynamic-text-header {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
    text-transform: uppercase;
    font-weight: 800;
  }

.dynamic-text-section {
  display: flex;
  background-color: white;
  padding: 30px;
}
.dynamic-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dynamic-text-item {
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .dynamic-text-heading {
    padding: 30px;
  }
  .dynamic-text-header {
    font-size: 0.8rem;
  }
  .dynamic-text-item p{
    font-size: 0.6rem;
  }
  .dynamic-text-container {
  }

}
