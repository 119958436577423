.button-rsvp-section {
    text-align: center;
    background-color: white;
    padding-bottom: 100px;
}

.button-rsvp-item {
  background-color: #408289;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 200px;
}
