.hero-section {
  position: relative;
  height: calc(
    100vh - 60px
  ); /* Subtract the navbar height from the viewport height */
  background-size: cover;
  background-position: center;
  text-align: center;
  overflow: hidden; /* Hide overflowing content */
  z-index: 0; /* Set z-index to place it below the pseudo-element */
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.408
  ); /* Background color for the text overlay */
  z-index: -1; /* Set z-index to place it below the content */
}

.hero-content {
  position: relative; /* Set position to relative for z-index to work */
  padding: 20px;
  border-radius: 10px;
  z-index: 1; /* Ensure the content is above the pseudo-element */
}

.hero-content h1 {
  font-size: 4rem;
  color: white;
  padding-top: 140px;
}

.hero-content p {
  font-size: 2rem;
  color: white;
}
