.gift-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
    background-color: white;
    margin-top: 20px;
    padding: 30px;
}

.gift-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gift-picture-text {
    border-radius: 20px;
    padding: 50px 10px;
    text-align: center;
}

.gift-picture-text img {
    max-width: 70%;
    height: auto;
    object-fit: cover;
}

@media (max-width: 768px) {
    .gift-section {
        flex-direction: column;
        align-items: center;
    }

}