/* src/components/NavigationBar.css */
.navbar {
  background-color: black;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  transition: background-color 0.2s ease;
  backdrop-filter: "blur(10px)"
}
.navbar-list {
  list-style: none;
  display: flex;
  gap: 25px;
}

.navbar-item a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: color 0.2s ease; 
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  padding: 10px 20px; /* Add padding to increase clickable area */
}

.hamburger {
  display: block;
  width: 25px;
  height: 20px;
  position: relative;
}
.bar {
  width: 100%;
  height: 3px;
  background-color: white;
  position: absolute;
  transition: all 0.3s ease;
  transform-origin: center;
}
.bar:nth-child(1) {
  top: 0;
}
.bar:nth-child(2) {
  top: 8px;
}
.bar:nth-child(3) {
  top: 16px;
}
.navbar-item.active a {
  font-weight: bold;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 30px 0;
  }
  .navbar.open {
    position: fixed;
    width: 35%;
    height: 100%;
    background-color: #000000;
    left: 0;
    transition: all 0.3s ease;
    z-index: 2;
  }
  .navbar.open .navbar-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 0px 0 0px;
    margin-top: 0;
    z-index: 1;
  }

  .navbar-list {
    display: none;
    z-index: 1;
    text-align: left;
  }

  .navbar-list.open {
    display: none;
    z-index: 1;
  }

  .menu-button {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: rotate(-315deg) translateY(10px);
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(3) {
    transform: rotate(318deg) translateY(-13px);
  }

  .navbar-item.active {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.772);
  }
  .navbar-item-element {
    padding: 20px 80px 20px 30px;
  }
  
}
