.wedding-text-section {
  background-color: #a79393;
  padding: 30px 0;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
.wedding-text-heading {
  font-size: 4rem;
  margin-bottom: 10px; 
  color: #ffffff;
  margin-top: 10px;
}
.wedding-text-subtitle {
  font-size: 2rem;
  color: #ffffff;
}

@media (max-width: 768px) {
  .wedding-text-heading {
    font-size: 2rem;
  }
  .wedding-text-subtitle {
    font-size: 1rem;
  }
}
