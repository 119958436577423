.hero-section-with-clock {
  position: relative;
  height: calc(
    100vh - 60px
  );
  background-size: cover;
  background-position: center;
  text-align: center;
  overflow: hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section-with-clock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.408
  );
  z-index: -1;
}

.hero-content-with-clock {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  z-index: 1;
  font-family: times;
}

.hero-content-with-clock h1 {
  font-size: 6rem;
  color: white;
  padding-top: 10px;
  font-family: times;
}

.hero-content-with-clock p {
  font-size: 2rem;
  color: white;
  font-family: times;
}

.timestamp-container-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px; /* Add some spacing between blocks */
}

.timestamp-block-hero {
  flex: 1;
  border-radius: 20px; /* Increase border-radius for larger boxes */
  padding: 80px 10px; /* Increase padding for larger boxes */
  text-align: center;
  color: white;
}

.timestamp-value-hero {
  font-size: 36px;
}

.timestamp-label-hero {
  font-size: 2rem;
}

/* Mobile-friendly styles for screens up to 768px wide */
@media (max-width: 768px) {

.hero-content-with-clock h1 {
    font-size: 4rem;
    color: white;
    padding-top: 10px;
    }
    
  .timestamp-container-hero {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px; /* Adjust the gap as needed */
    padding-top: 0;
  }

  .timestamp-block-hero {
    text-align: center;
    padding: 10px;
  }

  .timestamp-label-hero {
    font-size: 20px;
  }
}
